import { template as template_fb76fa9ec22a414c99d24c4e62176301 } from "@ember/template-compiler";
const WelcomeHeader = template_fb76fa9ec22a414c99d24c4e62176301(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
