import { template as template_c6b137a937794de8b9939c7c7344a93f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_c6b137a937794de8b9939c7c7344a93f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
